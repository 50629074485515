import { createAsyncThunk } from "@reduxjs/toolkit";

import { fetchWrapper, generateUpdateApiQueryParams } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "promotions";

// APIs
// Promotions
export const getPromotionsMetaData = createAsyncThunk(
  `${sliceName}/getPromotionsMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/promotions/getMetadata`)
);

export const getAllStore = createAsyncThunk(
  `${sliceName}/getAllStore`,
  async () => await fetchWrapper.get(`${baseUrl}/store/getAll`)
);

export const getAllCondition = createAsyncThunk(
  `${sliceName}/getAllCondition`,
  async () => await fetchWrapper.get(`${baseUrl}/conditions/getAll`)
);

export const getAllOffer = createAsyncThunk(
  `${sliceName}/getAllOffer`,
  async () => await fetchWrapper.get(`${baseUrl}/offers/getAll`)
);


export const getPromotionsRowData = createAsyncThunk(
  `${sliceName}/getPromotionsRowData`,
  async (params) => {
    const queryParams = new URLSearchParams(params);
    const type = queryParams.get('type');
    queryParams.delete('type');
    queryParams.append('searchKey[relations][][type]', type);
    return await fetchWrapper.get(`${baseUrl}/promotions/getAll?${queryParams || ""}`)
  }
);

export const deletePromotions = createAsyncThunk(
  `${sliceName}/deletePromotions`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/promotions/delete${deletePermanent ? "-permanent" : ""}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const addPromotions = createAsyncThunk(
  `${sliceName}/addPromotions`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/promotions/addPromotion`, values);
    return res || res === "" ? res : null;
  }
);

export const editPromotions = createAsyncThunk(
  `${sliceName}/editPromotions`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/promotions/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getPromotionsById = createAsyncThunk(
  `${sliceName}/getPromotionsById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/promotions/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const getPromotionsMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getPromotionsMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/promotions/getMetadata?versioning=true&${params || ""}`)
);

export const getPromotionsRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getPromotionsRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/promotions/version-history/get/${id}?${params || ""}`)
);

// PromotionsType
export const getPromotionsTypeMetaData = createAsyncThunk(
  `${sliceName}/getPromotionsTypeMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/promotion-types/getMetadata`)
);

export const getPromotionsTypeRowData = createAsyncThunk(
  `${sliceName}/getPromotionsTypeRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/promotion-types/getAll?${params || ""}`)
);

export const getPromotionsTypeList = createAsyncThunk(
  `${sliceName}/getPromotionsTypeList`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/promotion-type/dropdown/getAll?${params || ""}`)
);

export const deletePromotionsType = createAsyncThunk(
  `${sliceName}/deletePromotionsType`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/promotion-types/delete${deletePermanent ? "-permanent" : ""}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const getPromotionsTypeMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getPromotionsTypeMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/promotion-types/getMetadata?versioning=true&${params || ""}`)
);

export const getPromotionsTypeRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getPromotionsTypeRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/promotion-types/version-history/get/${id}?${params || ""}`)
);

export const addPromotionsType = createAsyncThunk(
  `${sliceName}/addPromotionsType`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/promotion-types/add`, values);
    return res || res === "" ? res : null;
  }
);

export const duplicatePromotionsType = createAsyncThunk(
  `${sliceName}/duplicatePromotionsType`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/promotion-types/duplicate/${id}`)
);

export const editPromotionsType = createAsyncThunk(
  `${sliceName}/editPromotionsType`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/promotion-types/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getPromotionsTypeById = createAsyncThunk(
  `${sliceName}/getPromotionsTypeById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/promotion-types/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);
// ConditionPropertyOperators
export const getConditionPropertyOperatorsMetaData = createAsyncThunk(
  `${sliceName}/getConditionPropertyOperatorsMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/condition-property-operators/getMetadata`)
);

export const getConditionPropertyOperatorsRowData = createAsyncThunk(
  `${sliceName}/getConditionPropertyOperatorsRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/condition-property-operators/getAll?${params || ""}`)
);

export const deleteConditionPropertyOperators = createAsyncThunk(
  `${sliceName}/deleteConditionPropertyOperators`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/condition-property-operators/delete${deletePermanent ? "-permanent" : ""}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const getConditionPropertyOperatorsMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getConditionPropertyOperatorsMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/condition-property-operators/getMetadata?versioning=true&${params || ""}`)
);

export const getConditionPropertyOperatorsRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getConditionPropertyOperatorsRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/condition-property-operators/version-history/get/${id}?${params || ""}`)
);

export const addConditionPropertyOperators = createAsyncThunk(
  `${sliceName}/addConditionPropertyOperators`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/condition-property-operators/add`, values);
    return res || res === "" ? res : null;
  }
);

export const duplicateConditionPropertyOperators = createAsyncThunk(
  `${sliceName}/duplicateConditionPropertyOperators`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/condition-property-operators/duplicate/${id}`)
);

export const editConditionPropertyOperators = createAsyncThunk(
  `${sliceName}/editConditionPropertyOperators`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/condition-property-operators/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getConditionPropertyOperatorsById = createAsyncThunk(
  `${sliceName}/getConditionPropertyOperatorsById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/condition-property-operators/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

// ConditionProperties
export const getConditionPropertiesMetaData = createAsyncThunk(
  `${sliceName}/getConditionPropertiesMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/condition-properties/getMetadata`)
);

export const getConditionPropertiesRowData = createAsyncThunk(
  `${sliceName}/getConditionPropertiesRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/condition-properties/getAll?${params || ""}`)
);

export const deleteConditionProperties = createAsyncThunk(
  `${sliceName}/deleteConditionProperties`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/condition-properties/delete${deletePermanent ? "-permanent" : ""}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const getConditionPropertiesMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getConditionPropertiesMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/condition-properties/getMetadata?versioning=true&${params || ""}`)
);

export const getConditionPropertiesRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getConditionPropertiesRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/condition-properties/version-history/get/${id}?${params || ""}`)
);

export const addConditionProperties = createAsyncThunk(
  `${sliceName}/addConditionProperties`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/condition-properties/add`, values);
    return res || res === "" ? res : null;
  }
);

export const duplicateConditionProperties = createAsyncThunk(
  `${sliceName}/duplicateConditionProperties`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/condition-properties/duplicate/${id}`)
);

export const editConditionProperties = createAsyncThunk(
  `${sliceName}/editConditionProperties`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/condition-properties/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getConditionPropertiesById = createAsyncThunk(
  `${sliceName}/getConditionPropertiesById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/condition-properties/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const getProductTypeRowData = createAsyncThunk(
  `${sliceName}/getProductTypeRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/product-type/getAll?${params || ""}`)
);

// OfferProperties
export const getOfferPropertiesMetaData = createAsyncThunk(
  `${sliceName}/getOfferPropertiesMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/offer-properties/getMetadata`)
);

export const getOfferPropertiesRowData = createAsyncThunk(
  `${sliceName}/getOfferPropertiesRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/offer-properties/getAll?${params || ""}`)
);

export const deleteOfferProperties = createAsyncThunk(
  `${sliceName}/deleteOfferProperties`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/offer-properties/delete${deletePermanent ? "-permanent" : ""}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const getOfferPropertiesMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getOfferPropertiesMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/offer-properties/getMetadata?versioning=true&${params || ""}`)
);

export const getOfferPropertiesRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getOfferPropertiesRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/offer-properties/version-history/get/${id}?${params || ""}`)
);

export const addOfferProperties = createAsyncThunk(
  `${sliceName}/addOfferProperties`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/offer-properties/add`, values);
    return res || res === "" ? res : null;
  }
);

export const duplicateOfferProperties = createAsyncThunk(
  `${sliceName}/duplicateOfferProperties`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/offer-properties/duplicate/${id}`)
);

export const editOfferProperties = createAsyncThunk(
  `${sliceName}/editOfferProperties`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/offer-properties/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getOfferPropertiesById = createAsyncThunk(
  `${sliceName}/getOfferPropertiesById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/offer-properties/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

// ConditionOfferAttributes
export const getConditionOfferAttributesMetaData = createAsyncThunk(
  `${sliceName}/getConditionOfferAttributesMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/condition-offer-attributes/getMetadata`)
);

export const getConditionOfferAttributesRowData = createAsyncThunk(
  `${sliceName}/getConditionOfferAttributesRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/condition-offer-attributes/getAll?${params || ""}`)
);

export const deleteConditionOfferAttributes = createAsyncThunk(
  `${sliceName}/deleteConditionOfferAttributes`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/condition-offer-attributes/delete${deletePermanent ? "-permanent" : ""}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const getConditionOfferAttributesMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getConditionOfferAttributesMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/condition-offer-attributes/getMetadata?versioning=true&${params || ""}`)
);

export const getConditionOfferAttributesRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getConditionOfferAttributesRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/condition-offer-attributes/version-history/get/${id}?${params || ""}`)
);

export const addConditionOfferAttributes = createAsyncThunk(
  `${sliceName}/addConditionOfferAttributes`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/condition-offer-attributes/add`, values);
    return res || res === "" ? res : null;
  }
);

export const duplicateConditionOfferAttributes = createAsyncThunk(
  `${sliceName}/duplicateConditionOfferAttributes`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/condition-offer-attributes/duplicate/${id}`)
);

export const editConditionOfferAttributes = createAsyncThunk(
  `${sliceName}/editConditionOfferAttributes`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/condition-offer-attributes/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getConditionOfferAttributesById = createAsyncThunk(
  `${sliceName}/getConditionOfferAttributesById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/condition-offer-attributes/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);



export const addPromotionCouponAssociation = createAsyncThunk(
  `${sliceName}/addPromotionCouponAssociation`,
  async ({ promotionId, values }) => {
    const res = await fetchWrapper.post(`${baseUrl}/promotion/${promotionId}/coupon/add`, values);
    return res || res === "" ? res : null;
  }
);


export const editPromotionCouponAssociation = createAsyncThunk(
  `${sliceName}/editPromotionCouponAssociation`,
  async ({ promotionId, values }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/promotion/${promotionId}/coupon/update`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getPromotionCouponAssociationMetaData = createAsyncThunk(
  `${sliceName}/getPromotionCouponAssociationMetaData`,
  async (promotionId) => await fetchWrapper.get(`${baseUrl}/promotion/coupon/getMetadata`)
);

export const getPromotionCouponAssociationRowData = createAsyncThunk(
  `${sliceName}/getPromotionCouponAssociationRowData`,
  async ({ promotionId, params }) =>
    await fetchWrapper.get(`${baseUrl}/promotion/${promotionId}/coupon/getAll?${params || ""}`)
);

export const getPromotionCouponAssociationById = createAsyncThunk(
  `${sliceName}/getPromotionCouponAssociationById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/promotion/${id}/coupon/get?${params}`
    )
  }
);

export const getPromotionMetaDataByPromotionTypeId = createAsyncThunk(
  `${sliceName}/getPromotionMetaDataByPromotionTypeId`,
  async (params) => await fetchWrapper.get(`${baseUrl}/promotions/getMetadata?${params || ""}`)
);

export const getMetalPropertyValuesMetaData = createAsyncThunk(
  `${sliceName}/getMetalPropertyValuesMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/metal/metal-property-values/getMetadata`)
);


export const getStonePropertyValuesMetaData = createAsyncThunk(
  `${sliceName}/getStonePropertyValuesMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/stone/stone-property-values/getMetadata`)
);

export const getCollectionPropertyValuesMetaData = createAsyncThunk(
  `${sliceName}/getCollectionPropertyValuesMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/collection/collection-property-values/getMetadata`)
);
