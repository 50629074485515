import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_utils";
// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const baseUiUrl = process.env.REACT_APP_UI_URL;
const sliceName = "commonData";

// APIs
export const getPropertyTypes = createAsyncThunk(
  `${sliceName}/getPropertyTypes`,
  async () => await fetchWrapper.get(`${baseUrl}/property-type/get`)
);

export const getApiVersion = createAsyncThunk(
  `${sliceName}/getApiVersion`,
  async () => await fetchWrapper.get(`${baseUrl}/readFile/version.json`)
);

export const getUiVersion = createAsyncThunk(
  `${sliceName}/getUiVersion`,
  async () => await fetchWrapper.get(`${baseUiUrl}/version.json`)
);

export const changeState = createAsyncThunk(
  `${sliceName}/changeState`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/${values.updateUrl}${values.id}`,
      values.data
    );
    return res || res === "" ? res : null;
  }
);

export const addNewTablePreview = createAsyncThunk(
  `${sliceName}/addNewTablePreview`,
  async (payload) => await fetchWrapper.post(`${baseUrl}/metadata-previews/add`, payload)
);

export const updateTablePreview = createAsyncThunk(
  `${sliceName}/updateTablePreview`,
  async (payload) => await fetchWrapper.post(`${baseUrl}/metadata-previews/update/${payload.id}`, payload)
);

export const getTablePreviews = createAsyncThunk(
  `${sliceName}/getTablePreviews`,
  async (id) => await fetchWrapper.get(`${baseUrl}/metadata-previews/getAll?tableId=${id}`)
);

export const deleteTablePreview = createAsyncThunk(
  `${sliceName}/deleteTablePreview`,
  async (id) => await fetchWrapper.post(`${baseUrl}/metadata-previews/delete/${id}`)
);


export const downloadFile = createAsyncThunk(
  `${sliceName}/deleteTablePreview`,
  async (fileName) => await fetchWrapper.get(`${baseUrl}/file-download/${fileName}/get`)
);

export const getAllRowsIds = createAsyncThunk(
  `${sliceName}/getAllRowsIds`,
  async ({ apiUrl }) => await fetchWrapper.get(`${baseUrl}/${apiUrl}`)
);

export const bulkDuplicates = createAsyncThunk(
  `${sliceName}/bulkDuplicates`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/${values.typeName}/batch-actions/duplicate`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const bulkDelete = createAsyncThunk(
  `${sliceName}/bulkDelete`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/${values.typeName}/batch-actions/delete`,
      values
    );
    return res || res === "" ? res : null;
  }
);


export const getAllStageOptions = createAsyncThunk(
  `${sliceName}/getAllStageOptions`,
  async ({ apiUrl, stateKey }) => await fetchWrapper.get(`${baseUrl}/${apiUrl}?stateKey=${stateKey}`)
);

export const updateState = createAsyncThunk(
  `${sliceName}/updateState`,
  async ({ values, apiUrl }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/${apiUrl}`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const getOrderEmailTemplateSettingMetaData = createAsyncThunk(
  `${sliceName}/getOrderEmailTemplateSettingMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/order/email-template-setting/getMetadata`)
);
export const getOrderEmailTemplateSettingRowData = createAsyncThunk(
  `${sliceName}/getOrderEmailTemplateSettingRowData`,
  async ({ params, orderId }) => await fetchWrapper.get(`${baseUrl}/order/email-template-setting/getAll?orderId=${orderId}&${params || ""}`)
);

export const updateOrderEmailTemplateSetting = createAsyncThunk(
  `${sliceName}/updateOrderEmailTemplateSetting`,
  async ({ values, orderId }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order/${orderId}/email-template-setting/update`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const sendOrderEmailTemplateSettingId = createAsyncThunk(
  `${sliceName}/sendOrderEmailTemplateSettingId`,
  async ({ orderEmailTemplateSettingId, orderId, translateId }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order/${orderId}/email-template-setting/${orderEmailTemplateSettingId}/send/${translateId}`
    );
    return res || res === "" ? res : null;
  }
);

// slice
export const commonSlice = createSlice({
  name: sliceName,
  initialState: {
    propertyValues: null,
    defaultLanguage: false,
    fieldSelectedLanguages: {},
    apiVersion: null,
    uiVersion: null,
    snackbar: {},
    stateOptions: [],
    stateData: {},
    tableState: null, // { tableId: ..., sortBy: ..., searchKey: ....}
    loadingFormLoader: false
  },
  reducers: {
    setDefaultLanguage: (state, action) => {
      return { ...state, defaultLanguage: action.payload }
    },
    setFieldSelectedLanguage: (state, action) => {
      const languages = { ...state.fieldSelectedLanguages };
      const { translations } = action.payload;
      languages[action.payload.field] = action.payload?.selectedLanguages;
      languages["list"] = translations.filter((l) => languages?.forms.includes(l.translate_id)).map((trns) => trns.code);
      if (languages?.forms?.length > 0) {
        localStorage.setItem('fieldSelectedLanguages', JSON.stringify(languages?.forms));
        localStorage.setItem('listViewSelectedLanguage', JSON.stringify(languages?.list));
      }
      return { ...state, fieldSelectedLanguages: languages }
    },
    setListViewSelectedLanguage: (state, action) => {
      const { translations } = action.payload;
      const languages = { ...state.fieldSelectedLanguages };
      languages[action.payload.field] = action.payload?.selectedLanguages;
      languages["forms"] = translations.filter((l) => languages?.list.includes(l.code)).map((trns) => trns.translate_id);
      if (languages?.list?.length > 0) {
        localStorage.setItem('listViewSelectedLanguage', JSON.stringify(languages?.list));
        localStorage.setItem('fieldSelectedLanguages', JSON.stringify(languages?.forms));
      }
      return { ...state, fieldSelectedLanguages: languages }
    },
    setStateOptions: (state, action) => {
      state.stateOptions = action.payload.options;
      state.stateData = action.payload.data;
    },
    setTableState: (state, action) => {
      state.tableState = {
        ...(state.tableState || {}),
        [action.payload.tableId]: action.payload,
      };
    },
    setFormLoaderState: (state, action) => {
      state.loadingFormLoader = action.payload
    },
  },
  extraReducers: {
    [getPropertyTypes.fulfilled]: (state, action) => {
      state.propertyValues = action.payload;
    },
    [getApiVersion.fulfilled]: (state, action) => {
      state.apiVersion = action.payload['api-version'];
    },
    [getUiVersion.fulfilled]: (state, action) => {
      state.uiVersion = action.payload['ui-version'];
    },
    [changeState.fulfilled]: (state, action) => {
      state.stateData = action.payload;
    }
  }
});

export const {
  setDefaultLanguage,
  setFieldSelectedLanguage,
  setListViewSelectedLanguage,
  setStateOptions,
  setTableState,
  setFormLoaderState,
} = commonSlice.actions;

export default commonSlice.reducer;
