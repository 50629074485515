/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Button } from "@mui/material";
import { FieldArray } from "formik";
import { useCallback, useEffect, useState } from "react";
import FeatureGroupItem from "./FeatureGroupItem";

const FeaturesGroup = ({
  prefixName = "",
  onChange,
  onBlur,
  value,
  onReplaceGroupList,
  featuresGroupData,
  setFieldValue,
  mediaData,
  disabled = false,
  collectionListData,
  action,
  name
}) => {
  const emptyFeatureGroup = {
    collectionId: "",
    featureGroupId: "",
    mediaTypeGroupId: "",
    sequence: 1,
  };
  const [isedited, setIsedited] = useState(false);
  const [collectionFeature, setCollectionFeature] = useState([]);

  function getNestedValue(obj, path) {
    return path.split(/[.[\]]/).filter(Boolean).reduce((acc, key) => acc?.[key], obj);
  }

  const finalValues = getNestedValue(value, name);
  let collectionList = finalValues?.length > 0 ? finalValues : [emptyFeatureGroup];
  const setGroupValue = useCallback(
    (val) => {
      const data = collectionList;
      setCollectionFeature(data);
      if (typeof setFieldValue === "function") {
        setFieldValue(`${name}`, data);
      }
      if (typeof onReplaceGroupList === "function") {
        setCollectionFeature(data);
        onReplaceGroupList(val);
      }
    },
    [setFieldValue, finalValues]
  );

  useEffect(() => {
    if (
      (action === "edit" || action === "view") && isedited &&
      collectionList?.length > 0
    ) {
      setGroupValue(collectionList);
      setIsedited(!isedited);
    } else {
      setGroupValue([emptyFeatureGroup]);
    }
  }, [setGroupValue, action]);

  const handleremoveArray = (subSeqindex) => {
    let array = JSON.parse(JSON.stringify(collectionFeature));
    const newItem = array[subSeqindex];
    const result = array?.filter(
      (item) => item?.sequence !== newItem?.sequence
    );
    setFieldValue(`${name}`, result);
    setCollectionFeature(result);
  };

  const handleSequenceArray = (type, subSeqindex) => {
    let array = JSON.parse(JSON.stringify(collectionFeature));
    if (type === "down" && subSeqindex < array.length - 1) {
      const item = array[subSeqindex];
      array.splice(subSeqindex, 1);
      array.splice(subSeqindex + 1, 0, item);
    } else if (type === "up" && subSeqindex > 0) {
      const item = array[subSeqindex];
      array.splice(subSeqindex, 1);
      array.splice(subSeqindex - 1, 0, item);
    }
    array.map((item, subSeqindex) => item.sequence = subSeqindex + 1)
    let updatedData = array;

    setFieldValue(`${name}`, updatedData);
    setCollectionFeature(updatedData);
  };

  return (
    <>
      <div className="form-group drag_and_drop_block">
        <Divider />
        {collectionFeature?.length > 0 && (
          <>
            <FieldArray
              name={`${name}`}
              render={(formOption) =>
                collectionFeature?.sort((a, b) => a?.sequence - b?.sequence)?.map((blockValue, sIndex) => (
                  <FeatureGroupItem
                    prefixName={prefixName}
                    key={`${sIndex}-collectionTypeId-${blockValue?.collectionId}`}
                    index={sIndex}
                    formOption={formOption}
                    featuresGroupData={featuresGroupData}
                    value={blockValue}
                    collections={value?.includeCollections}
                    onBlur={onBlur}
                    onChange={onChange}
                    mediaData={mediaData}
                    isLast={sIndex === collectionFeature.length - 1}
                    disabled={disabled}
                    collectionList={collectionListData}
                    setFieldValue={setFieldValue}
                    removeElement={handleremoveArray}
                    collectionFeature={collectionFeature}
                    handleFeatureSequence={(type, index) => {
                      handleSequenceArray(type, index)
                    }}
                  />
                ))
              }
            />

            <div className="w-100 mb-2 d-flex justify-content-end">
              {!disabled && (
                <Button
                  variant="outlined"
                  sx={{
                    mt: 1,
                    mb: 1,
                  }}
                  size="small"
                  disabled={disabled}
                  className="m-0 mt-1 ml-2"
                  onClick={() => {
                    collectionFeature?.push({
                      collectionId: "",
                      featureGroupId: "",
                      mediaTypeGroupId: "",
                      sequence: collectionFeature?.length + 1,
                    });
                    setFieldValue(`${name}`, collectionFeature);
                  }}
                >
                  <span className="mr-1 material-icons">add</span>
                  Add
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default FeaturesGroup;
