import Modal from "@mui/material/Modal";
import OptionTable from "./optionTable";
import { IconButton } from "@mui/material";
import DependentDropdown from "./dependentDropdown";

function SelectModal({
  name,
  open,
  handleClose,
  setFieldValue,
  fieldName,
  defaultSelected,
  getMetaData,
  getRowData,
  selectType,
  maxLimit,
  isTypeSelect,
  getsTypesRowData,
  getTagById,
  dataList,
  types,
  paramTypeIdName,
  isImageUrl = true,
  onConfirm,
  selectedListLabelMap, // to map bottom selected option list label and id,
  customParam,
  isActionHide,
  hideConfirm,
  staticValues = false,
  isDropdown = false,
  ...rest
}) {
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <div className={`select-table-popup ${isDropdown && 'select-table-dropdown-popup'}`} >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="btn-close"
            sx={{
              position: "absolute",
              right: 0,
              top: -4,
              zIndex: 9,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
          {
            isDropdown ?
              <DependentDropdown
                getRowData={getRowData}
                handleClose={handleClose}
                onConfirm={(ids, optionList) => {
                  if (selectType === "multiSelect") {
                    setFieldValue(fieldName, ids);
                  }
                  onConfirm(optionList);
                  handleClose();
                }}
                staticValues={staticValues}
                defaultSelected={defaultSelected}
              /> :
              <OptionTable
                onConfirm={(ids, optionList, imageUrls) => {
                  if (selectType === "multiSelect") {
                    setFieldValue(fieldName, ids);
                  } else {
                    isImageUrl ? setFieldValue(fieldName, ids?.[0]) : setFieldValue(fieldName, imageUrls?.[0]);
                  }

                  onConfirm(optionList);
                  handleClose();
                }}
                handleClose={handleClose}
                defaultSelected={defaultSelected}
                open={open}
                name={name}
                getMetaData={getMetaData}
                getRowData={getRowData}
                selectType={selectType}
                maxLimit={maxLimit}
                isTypeSelect={isTypeSelect}
                getsTypesRowData={getsTypesRowData}
                getTagById={getTagById}
                dataList={dataList}
                types={types}
                paramTypeIdName={paramTypeIdName}
                selectedListLabelMap={selectedListLabelMap}
                customParam={customParam}
                isActionHide={isActionHide}
                hideConfirm={hideConfirm}
                staticValues={staticValues}
                {...rest}
              />
          }
        </div>
      </Modal>
    </div>
  );
}

export default SelectModal