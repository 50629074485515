import { createAsyncThunk } from "@reduxjs/toolkit";

import { fetchWrapper, generateUpdateApiQueryParams } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "customersData";

// APIs

// Customer
export const getCustomerMetaData = createAsyncThunk(
  `${sliceName}/getCustomerMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/customer/getMetadata`)
);

export const getCustomerRowData = createAsyncThunk(
  `${sliceName}/getCustomerRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/customer/getAll?${params || ""}`)
);

export const deleteCustomer = createAsyncThunk(
  `${sliceName}/deleteCustomer`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(`${baseUrl}/customer/delete${deletePermanent ? '-permanent' : ''}/${id}`, { id });
    return res || res === "" ? { id } : null;
  }
);

export const addCustomer = createAsyncThunk(
  `${sliceName}/addCustomer`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/customer/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editCustomer = createAsyncThunk(
  `${sliceName}/editCustomer`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/customer/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getCustomerDataById = createAsyncThunk(
  `${sliceName}/getCustomerDataById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(`${baseUrl}/customer/get/${id}${params ? `?${params}` : ''}`)
  }
);

export const getCollectionsById = createAsyncThunk(
  `${sliceName}/getCollectionsById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/collection/get/${id}`)
);

export const getCustomerMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getCustomerMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/customer/getMetadata?versioning=true&${params || ""}`)
);

export const getCustomerRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getCustomerRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/customer/version-history/get/${id}?${params || ""}`)
);

export const duplicateCustomer = createAsyncThunk(
  `${sliceName}/duplicateCustomer`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/customer/duplicate/${id}`)
);


// CustomerEnquiriesEnquiries
export const getCustomerEnquiriesMetaData = createAsyncThunk(
  `${sliceName}/getCustomerEnquiriesMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/customer-enquiries/getMetadata`)
);

export const getCustomerEnquiriesRowData = createAsyncThunk(
  `${sliceName}/getCustomerEnquiriesRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/customer-enquiries/getAll?${params || ""}`)
);

export const deleteCustomerEnquiries = createAsyncThunk(
  `${sliceName}/deleteCustomerEnquiries`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(`${baseUrl}/customer-enquiries/delete${deletePermanent ? '-permanent' : ''}/${id}`, { id });
    return res || res === "" ? { id } : null;
  }
);

export const addCustomerEnquiries = createAsyncThunk(
  `${sliceName}/addCustomerEnquiries`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/customer-enquiries/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editCustomerEnquiries = createAsyncThunk(
  `${sliceName}/editCustomerEnquiries`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/customer-enquiries/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getCustomerEnquiriesDataById = createAsyncThunk(
  `${sliceName}/getCustomerEnquiriesDataById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(`${baseUrl}/customer-enquiries/get/${id}${params ? `?${params}` : ''}`)
  }
);

export const getCustomerEnquiriesMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getCustomerEnquiriesMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/customer-enquiries/getMetadata?versioning=true&${params || ""}`)
);

export const getCustomerEnquiriesRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getCustomerEnquiriesRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/customer-enquiries/version-history/get/${id}?${params || ""}`)
);

export const duplicateCustomerEnquiries = createAsyncThunk(
  `${sliceName}/duplicateCustomerEnquiries`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/customer-enquiries/duplicate/${id}`)
);



// CustomersEnquiriyRequests
export const getCustomersEnquiriyRequestsMetaData = createAsyncThunk(
  `${sliceName}/getCustomersEnquiriyRequestsMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/customer-enquiry-requests/getMetadata`)
);

export const getCustomersEnquiriyRequestsRowData = createAsyncThunk(
  `${sliceName}/getCustomersEnquiriyRequestsRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/customer-enquiry-requests/getAll?${params || ""}`)
);

export const getCustomersEnquiriyRequestsList = createAsyncThunk(
  `${sliceName}/getCustomersEnquiriyRequestsList`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/customer-enquiry-requests/dropdown/getAll?${params || ""}`)
);

export const deleteCustomersEnquiriyRequests = createAsyncThunk(
  `${sliceName}/deleteCustomersEnquiriyRequests`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(`${baseUrl}/customer-enquiry-requests/delete${deletePermanent ? '-permanent' : ''}/${id}?deleted=true`, { id });
    return res || res === "" ? { id } : null;
  }
);

export const addCustomersEnquiriyRequests = createAsyncThunk(
  `${sliceName}/addCustomersEnquiriyRequests`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/customer-enquiry-requests/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editCustomersEnquiriyRequests = createAsyncThunk(
  `${sliceName}/editCustomersEnquiriyRequests`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/customer-enquiry-requests/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getCustomersEnquiriyRequestsDataById = createAsyncThunk(
  `${sliceName}/getCustomersEnquiriyRequestsDataById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(`${baseUrl}/customer-enquiry-requests/get/${id}${params ? `?${params}` : ''}`)
  }
);

export const getCustomersEnquiriyRequestsMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getCustomersEnquiriyRequestsMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/customer-enquiry-requests/getMetadata?versioning=true&${params || ""}`)
);

export const getCustomersEnquiriyRequestsRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getCustomersEnquiriyRequestsRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/customer-enquiry-requests/version-history/get/${id}?${params || ""}`)
);

export const duplicateCustomersEnquiriyRequests = createAsyncThunk(
  `${sliceName}/duplicateCustomersEnquiriyRequests`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/customer-enquiry-requests/duplicate/${id}`)
);


export const getCustomerData = createAsyncThunk(
  `${sliceName}/getCustomerSlice`,
  async () => await fetchWrapper.get(`${baseUrl}/customer?filter=`)
);

// bespoke enquiries

export const getBespokeEnquiriesMetaData = createAsyncThunk(
  `${sliceName}/getBespokeEnquiriesMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/bespoke-enquiries/getMetadata`)
);

export const getBespokeEnquiriesRowData = createAsyncThunk(
  `${sliceName}/getBespokeEnquiriesRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/bespoke-enquiries/getAll?${params || ""}`)
);

export const deleteBespokeEnquiries = createAsyncThunk(
  `${sliceName}/deleteBespokeEnquiries`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(`${baseUrl}/bespoke-enquiries/delete${deletePermanent ? '-permanent' : ''}/${id}?deleted=true`, { id });
    return res || res === "" ? { id } : null;
  }
);

export const addBespokeEnquiries = createAsyncThunk(
  `${sliceName}/addBespokeEnquiries`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/bespoke-enquiries/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editBespokeEnquiries = createAsyncThunk(
  `${sliceName}/editBespokeEnquiries`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/bespoke-enquiries/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getBespokeEnquiriesDataById = createAsyncThunk(
  `${sliceName}/getBespokeEnquiriesDataById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/bespoke-enquiries/get/${id}`)
);

export const getBespokeEnquiriesMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getBespokeEnquiriesMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/bespoke-enquiries/getMetadata?versioning=true&${params || ""}`)
);

export const getBespokeEnquiriesRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getBespokeEnquiriesRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/bespoke-enquiries/version-history/get/${id}?${params || ""}`)
);

export const duplicateBespokeEnquiries = createAsyncThunk(
  `${sliceName}/duplicateBespokeEnquiries`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/bespoke-enquiries/duplicate/${id}`)
);

// BespokeQuestionnairesEnquiries
export const getBespokeQuestionnairesMetaData = createAsyncThunk(
  `${sliceName}/getBespokeQuestionnairesMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/bespoke-questionnaires/getMetadata`)
);

export const getBespokeQuestionnairesRowData = createAsyncThunk(
  `${sliceName}/getBespokeQuestionnairesRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/bespoke-questionnaires/getAll?${params || ""}`)
);

export const deleteBespokeQuestionnaires = createAsyncThunk(
  `${sliceName}/deleteBespokeQuestionnaires`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(`${baseUrl}/bespoke-questionnaires/delete${deletePermanent ? '-permanent' : ''}/${id}?deleted=true`, { id });
    return res || res === "" ? { id } : null;
  }
);

export const addBespokeQuestionnaires = createAsyncThunk(
  `${sliceName}/addBespokeQuestionnaires`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/bespoke-questionnaires/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editBespokeQuestionnaires = createAsyncThunk(
  `${sliceName}/editBespokeQuestionnaires`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/bespoke-questionnaires/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getBespokeQuestionnairesDataById = createAsyncThunk(
  `${sliceName}/getBespokeQuestionnairesDataById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/bespoke-questionnaires/get/${id}`)
);

export const getBespokeQuestionnairesMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getBespokeQuestionnairesMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/bespoke-questionnaires/getMetadata?versioning=true&${params || ""}`)
);

export const getBespokeQuestionnairesRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getBespokeQuestionnairesRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/bespoke-questionnaires/version-history/get/${id}?${params || ""}`)
);

export const duplicateBespokeQuestionnaires = createAsyncThunk(
  `${sliceName}/duplicateBespokeQuestionnaires`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/bespoke-questionnaires/duplicate/${id}`)
);


// DropHint
export const getDropHintMetaData = createAsyncThunk(
  `${sliceName}/getDropHintMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/drop-hint/getMetadata`)
);

export const getDropHintRowData = createAsyncThunk(
  `${sliceName}/getDropHintRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/drop-hint/getAll?${params || ""}`)
);

export const deleteDropHint = createAsyncThunk(
  `${sliceName}/deleteDropHint`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(`${baseUrl}/drop-hint/delete${deletePermanent ? '-permanent' : ''}/${id}`, { id });
    return res || res === "" ? { id } : null;
  }
);

export const addDropHint = createAsyncThunk(
  `${sliceName}/addDropHint`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/drop-hint/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editDropHint = createAsyncThunk(
  `${sliceName}/editDropHint`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/drop-hint/update/${values.id}`,
      values?.formData
    );
    return res || res === "" ? values : null;
  }
);

export const getDropHintDataById = createAsyncThunk(
  `${sliceName}/getDropHintDataById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(`${baseUrl}/drop-hint/get/${id}${params ? `?${params}` : ''}`)
  }
);

export const getDropHintMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getDropHintMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/drop-hint/getMetadata?versioning=true&${params || ""}`)
);

export const getDropHintRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getDropHintRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/drop-hint/version-history/get/${id}?${params || ""}`)
);


// RingSizeGuide
export const getRingSizeGuideMetaData = createAsyncThunk(
  `${sliceName}/getRingSizeGuideMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/ring-size-guide/getMetadata`)
);

export const getRingSizeGuideRowData = createAsyncThunk(
  `${sliceName}/getRingSizeGuideRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/ring-size-guide/getAll?${params || ""}`)
);

export const deleteRingSizeGuide = createAsyncThunk(
  `${sliceName}/deleteRingSizeGuide`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(`${baseUrl}/ring-size-guide/delete${deletePermanent ? '-permanent' : ''}/${id}`, { id });
    return res || res === "" ? { id } : null;
  }
);

export const addRingSizeGuide = createAsyncThunk(
  `${sliceName}/addRingSizeGuide`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/ring-size-guide/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editRingSizeGuide = createAsyncThunk(
  `${sliceName}/editRingSizeGuide`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/ring-size-guide/update/${values.id}`,
      values?.formData
    );
    return res || res === "" ? values : null;
  }
);

export const getRingSizeGuideDataById = createAsyncThunk(
  `${sliceName}/getRingSizeGuideDataById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(`${baseUrl}/ring-size-guide/get/${id}${params ? `?${params}` : ''}`)
  }
);

export const getRingSizeGuideMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getRingSizeGuideMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/ring-size-guide/getMetadata?versioning=true&${params || ""}`)
);

export const getRingSizeGuideRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getRingSizeGuideRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/ring-size-guide/version-history/get/${id}?${params || ""}`)
);