import React from "react";
import 'quill/dist/quill.snow.css'
import ReactQuill, { Quill } from 'react-quill';
import QuillResizeImage from "quill-resize-image";

Quill.register("modules/resize", QuillResizeImage);

const MagicTextEditor = ({ name, label, value, setFieldValue, disabled }) => {
    const modules = {
        toolbar: {
            container: [
                [{ header: [1, 2, 3, 4, 5, false] }],
                [{ size: ["small", false, "large", "huge"] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                ["link", "image"],
                [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
                [{ color: ["#ffffff","#000000", "#957127", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff"] }],
            ]
        },
        resize: {
            locale: {}
        },
    };

    const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "color", "bullet", "indent", "link", "image", "align", "size"];

    return (
        <div className="w-full">

            {/* <StyledQuill
                value={value}
                onChange={(content) => setFieldValue(name, content)}
                modules={modules}
                formats={formats}
                theme="snow"
                readOnly={disabled}
                placeholder={label || "Write something..."}
            /> */}

            <ReactQuill
                value={value}
                onChange={(content) => setFieldValue(name, content)}
                modules={modules}
                formats={formats}
                theme="snow"
                className="w-[100%]"
                readOnly={disabled}
                placeholder={label}
            />
        </div>
    );
};

export default MagicTextEditor;