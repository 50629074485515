import { orderBy } from "lodash";
import { store } from "../_store";
import { uuidv4 } from "./commonFunctions";

const sortDataByLevel = (a = {}, b = {}) => {
  if (a.level === b.level) {
    return 0;
  }
  let splitA = a?.level?.split('.') ?? [];
  let splitB = b?.level?.split('.') ?? [];
  const length = Math.max(splitA.length, splitB.length);
  for (let i = 0; i < length; i++) {
    // FLIP
    if (parseInt(splitA[i]) > parseInt(splitB[i]) ||
      ((splitA[i] === splitB[i]) && isNaN(splitB[i + 1]))) {
      return 1;
    }
    // DON'T FLIP
    if (parseInt(splitA[i]) < parseInt(splitB[i]) ||
      ((splitA[i] === splitB[i]) && isNaN(splitA[i + 1]))) {
      return -1;
    }
  }
}


const mergeParentChildData = (obj, mergeData) => {
  if (!mergeData) {
    return obj;
  }

  const fixedDataKey = ["id", "level", "isRoot", "idHierarchy", "parentColKey", "nestedColumns", "renderId"];

  Object.entries(mergeData).map(([key, value]) => {
    if (!fixedDataKey.includes(key) && !obj.hasOwnProperty(key)) {
      obj[key] = value;
    }
    return key;
  });

  return { ...obj };
}

const mergeDataWithParentAtAllLevel = (data, parentData) => {

  let newArr = data.reduce((acc, item) => {
    Object.keys(item).forEach(key => {
      if (item[key] && Array.isArray(item[key].nestedColumns) && item[key].nestedColumns.length) {
        const sortedChildData = item[key].nestedColumns.sort(sortDataByLevel); // Sort data by level
        let childArray = mergeDataWithParentAtAllLevel(sortedChildData, item);
        const firstElement = childArray[0];
        childArray = childArray.filter((_, index) => index !== 0); // Remove first element
        acc = acc.concat(childArray);
        item = mergeParentChildData(item, firstElement); // Bind same date for nested
      }
    });
    // Remove nested object
    delete item.nestedColumns;

    item = mergeParentChildData(item, parentData); // Bind same date for nested
    acc.push({ ...item });
    return acc;
  }, []);
  return newArr;
}

const injectActionColumnDetail = (data) => {
  const rootRenderIds = data.filter(i => i.isRoot && i.renderId).map(i => i.renderId);
  let updatedData = data.map(item => {
    if (item.isRoot && rootRenderIds.includes(item.renderId)) {
      item.action = {
        renderId: item.renderId
      }
    }

    return item;
  });

  return updatedData.map(item => {
    updatedData.filter(i => {
      if (i.level !== item.level && item.level.indexOf(i.level) === 0 && i.action) {
        item.statusCode = i.statusCode; // Status code will override as per the root row
        // item.action = i.action;
      }
      return false;
    });

    return item;
  });
}


const getArrayToObjectOrString = (data, translations) => {
  const obj = {};
  if (!Array.isArray(data) || !data) {
    return data;
  }

  const hasObject = data.some(i => typeof i === "object");

  if (!hasObject) {
    return data?.filter(i => i !== '')?.join(', ');
  }

  data.forEach(i => {
    Object.keys(i).forEach(key => {
      const val = i[key];
      // Set on array for
      // Multi select feature
      if (Array.isArray(obj[key])) {
        obj[key].push(val);
      } else {
        obj[key] = [val]; // Create an array and add value
      }
    });
  });

  // Convert to string
  // Multi select with translation
  Object.entries(obj).forEach(([key, value]) => {
    obj[key] = value.join(", ");
  });

  return obj;
}

const readNestedValues = (obj, mapping, columnData) => {
  const { translations } = store.getState();

  if (mapping.length === 0) {
    // if further mapping is not available, then look for data as per datakeys and return values accordingly
    let finalValue = "";
    // check if multiple data keys to create a label for just ui representations

    //if translate-id.. we will print en / us etc based on the translation data available with us
    if (obj && Object.hasOwn(obj, "translateId") && columnData.translatable) {
      // check if the translate Id of the current object is present in translations,
      const result = translations && !translations.error ? translations?.find(
        (translation) => translation.translate_id === obj.translateId
      ) : [];
      //if translate Id exists, return the translate name in final value
      if (result) {
        finalValue = { [obj.translateId]: columnData.datakey.map((item) => obj[item])?.join() }; //.join(",")
      } else {
        // translate id not found.
      }
    } else {
      // if not transaction id, the read directly 
      finalValue = obj && columnData.datakey ? columnData.datakey?.map((item) => obj[item]) : "-";
      // If not translatable and get array
      if (Array.isArray(finalValue) && !columnData.isMedia) {
        finalValue = finalValue.filter(i => (typeof i === 'number' ? String(i) : i)).join(", ");
      }
    }
    return finalValue;
  } else {
    //check if further mapping is available, then trigger recursive method based on object type
    const { key } = mapping[0] || {};
    const nextObj = obj?.[key] || {};
    const remainingMapping = mapping.slice(1);
    if (nextObj) {
      if (Array.isArray(nextObj)) {
        const dd = nextObj.map((element) =>
          readNestedValues(element, remainingMapping, columnData)
        );

        if (remainingMapping.length === 1) {
          return dd; // Take out multi nested to upper level
        }

        return getArrayToObjectOrString(dd, translations);
      } else {

        const nestData = readNestedValues(
          nextObj,
          remainingMapping,
          columnData
        );
        return columnData.isMedia ? nestData : getArrayToObjectOrString(nestData, translations);
      }
    } else {
      return "";
    }
  }
};

const nestedToFlattenArray = (arr) => {
  const newArr = arr.reduce((acc, item) => {
    if (Array.isArray(item)) {
      acc = acc.concat(nestedToFlattenArray(item));
    } else {
      acc.push(item);
    }

    return acc;
  }, []);

  return newArr;
}

const getRandomId = () => {
  return uuidv4();
}

const getParentDataByMapping = (obj, mapping, columnData) => {
  if (mapping.length === 0) {
    return obj[columnData.id] || obj[columnData.keyName] || [];
  } else {
    //check if further mapping is available, then trigger recursive method based on object type
    const { key } = mapping[0] || {};
    const nextObj = obj?.[key] || {};
    const remainingMapping = mapping.slice(1);
    if (nextObj) {
      if (Array.isArray(nextObj)) {

        if (!remainingMapping.length) {
          return nextObj;
        }

        const dd = nextObj.map((element) =>
          getParentDataByMapping(element, remainingMapping, columnData)
        );

        return nestedToFlattenArray(dd);
      }
      else if (typeof nextObj === 'object') {
        if (!remainingMapping.length) {
          return [nextObj];
        }
        const dd = getParentDataByMapping(nextObj, remainingMapping, columnData)
        return [dd]
      }
      else {
        const nestData = getParentDataByMapping(
          nextObj,
          remainingMapping,
          columnData
        );
        return nestData;
      }
    } else {
      return "";
    }
  }
}

const getDataByColumn = (columnData, col, allColumnMappings, level, parentColKey = "") => {
  let updatedObject = {};

  let keyMappingOrder = [];
  if (Object.keys(col.keymapping)?.length) {

    if (!col.datakey || !Array.isArray(col.datakey)) {
      col.datakey = [];
    }

    for (const [key, value] of Object.entries(col.keymapping)) {
      keyMappingOrder.push({ key: key, ...value });
    }

    keyMappingOrder = orderBy(keyMappingOrder, "heirarchy", "asc");
    const data = readNestedValues(
      columnData,
      keyMappingOrder,
      col
    );

    updatedObject[col.keyName] = {
      translatable: col.translatable,
      data,
      parentColKey
    };

  } else {
    let value = "";
    // Take data from object only for nested column
    if (col.isNestedCol && Array.isArray(col.datakey) && col.datakey.length) {
      value = readNestedValues(
        columnData,
        [],
        col
      );
    }

    value = value || columnData[col.keyName];
    updatedObject[col.keyName] = {
      translatable: false,
      data: ["string", "number", 'boolean'].includes(typeof value) ? String(value) : (value || ""),
      parentColKey
    };
  }
  updatedObject.statusCode = columnData[col.statusCode] || '';
  updatedObject[col.keyName].isSameAsActionCol = !!col.isSameAsActionCol;
  updatedObject[col.keyName].rowId = columnData.id || "";
  updatedObject[col.keyName].renderId = col.renderId || ""; // Render id user for nested level view
  updatedObject.level = `${level}`;
  updatedObject[col.keyName].level = `${level}`;
  if (col.hasNestedColumns) {
    updatedObject[col.keyName].data = "";
    const parentColData = getParentDataByMapping(columnData, keyMappingOrder, col);

    const nestedColumns = allColumnMappings.filter(c =>
      c.columnMapping && c.columnMapping === col.keyName
    );
    updatedObject[col.keyName].nestedColumns = [];

    if (!parentColData || !parentColData.length) {
      return updatedObject;
    }

    parentColData.map((data, index) => {
      let nestedData = {
        id: data.id,
        parentColKey: col.keyName,
        level: `${level}.${index + 1}`
      };
      nestedColumns.map((nestCol) => {
        Object.keys(col.keymapping).forEach(colKey => {
          if (nestCol.keymapping.hasOwnProperty(colKey)) {
            delete nestCol.keymapping[colKey];
          }
        });
        nestCol.renderId = getRandomId();
        nestCol.isNestedCol = true;

        nestedData = {
          ...nestedData,
          ...getDataByColumn(data, nestCol, allColumnMappings, nestedData.level, col.keyName)
        }
        return nestCol;
      });
      updatedObject[col.keyName].nestedColumns.push(nestedData);
      return data;
    });
  }
  return updatedObject;
}

// Get Nested Columns Mapping
const flattenDataByColumnMapping = (columnData, columnsMappings, index) => {
  let updatedObject = {
    id: columnData.id,
    objectId: columnData.objectId,
    columnData,
    itemState: columnData.itemState,
    ...(columnData.highlight ? { highlight: columnData.highlight } : {}),
    level: index,
    actionAccess: columnData?.actions ?? {},
  };
  let hasFindSameLevelOfAction = true;
  columnsMappings.map((col, colIndex) => {
    if (!col.keyName) {
      return null;
    }

    if (!col.columnMapping && !col.nestedLevel) {
      col.isSameAsActionCol = hasFindSameLevelOfAction;
      const renderId = getRandomId();
      col.renderId = renderId;

      col.actionRefIndex = colIndex;
      updatedObject = {
        ...updatedObject,
        renderId,
        isRoot: true,
        ...getDataByColumn(columnData, col, columnsMappings, index)
      };
      hasFindSameLevelOfAction = false;
    }
    return col;
  });

  return updatedObject;

};

const sortedColMapping = (colMapping) => {
  return colMapping.sort((a, b) => {
    if (a.hasNestedColumns || b.hasNestedColumns) {
      if (a.nestedLevel && b.nestedLevel) {
        return a.nestedLevel - b.nestedLevel;
      }
      return 1
    }

    if (a.nestedLevel && b.nestedLevel) {
      return a.nestedLevel - b.nestedLevel;
    }

    return 0;
  });
}

export const removeDuplicateColumn = (columns) => {
  const uniqueCols = [];

  columns.map(col => {
    const hasCol = uniqueCols.some(i => i.id === col.id);
    if (!hasCol) {
      uniqueCols.push(col);
    }
    return col;
  });

  return uniqueCols;
}

export const getNestedTableFormattedData = (data = [], columnsMappings = []) => {
  const columns = sortedColMapping(removeDuplicateColumn(columnsMappings));

  let formattedData = data.map((item, index) => {
    return flattenDataByColumnMapping(item, columns, index + 1);
  });
  formattedData = mergeDataWithParentAtAllLevel(formattedData).sort(sortDataByLevel);
  formattedData = injectActionColumnDetail(formattedData);
  return formattedData;
}
