import * as React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getActionName } from "../../_utils/commonFunctions";

const HeaderTitle = ({
  action,
  title,
  type,
  onBackClick,
  subText,
  setOpen,
  id,
  secondaryTitle,
  isBackButton = true,
  secondaryButton,
  redirectPath,
  isLatestRecord,
  showTag,
  additionalButtons = [],
}) => {
  const navigate = useNavigate();
  return (
    <div className="headerTitleRow">
      <h1>{getActionName(action)} {title} {subText ? <small className="fs-body"> - {subText}</small> : ''} </h1>
      {(action && showTag && !isLatestRecord) && <h4 className="px-2 bg-red ml-auto mt-2">Old Record</h4>}
      {secondaryButton &&
        <Button
          type="button"
          variant="outlined"
          sx={{ marginLeft: "auto" }}
          size="large"
          onClick={() => {
            navigate(redirectPath)
          }}
        >
          {secondaryButton}
        </Button>
      }
      {
        id &&
        <Button
          type="button"
          variant="outlined"
          sx={{ marginLeft: "auto" }}
          size="large"
          onClick={() => {
            setOpen(true)
          }}
        >
          {secondaryTitle}
        </Button>
      }
      {additionalButtons.map(({
        variant, size, classess = '', onClick = () => { }, sx = {}, ...props
      }) => {
        return <Button
          type="button"
          variant={`${variant || 'outlined'}`}
          size={`${size || 'large'}`}
          onClick={onClick}
          className={classess}
          sx={{ marginLeft: "auto", ...sx }}
          {...props}
        >
          {props.title}
        </Button>
      })}
      {isBackButton && <Button type="button" sx={{ marginLeft: "5px" }} onClick={() => onBackClick ? onBackClick() : navigate("../", { state: { type } })} variant="default">
        <span className="mr-1 material-icons">chevron_left</span> Back to
        Listing
      </Button>}
    </div>
  );
};

export default HeaderTitle;
