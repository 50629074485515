import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import AutocompleteForFormikMulti from "_components/AutocompleteForFormikMulti";
import { Field, Formik } from 'formik';
import { CardBody } from '_components/pim';
import AutocompleteForFormik from '_components/AutocompleteForFormik';
import { Button } from '@mui/material';

const DependentDropdown = ({
  getRowData,
  staticValues,
  onConfirm,
  handleClose,
  defaultSelected
}) => {
  const [dataLoading, setDataLoading] = useState(true);
  const [propertyDropdown, setPropertyDropdown] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedValuesObject, setSelectedValuesObject] = useState([]);
  const [valuesDropdown, setValuesDropdown] = useState([]);
  const [initialData, setInitialData] = useState({ property: "", values: "" })
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRowData()).then((response) => {
      if (response?.payload?.data?.length > 0) {
        const featuresOptionDataResponse = response?.payload?.data?.map((i) => ({
          value: i.id,
          label: i?.name || i.id,
          childValue: i?.values ?? []
        }));
        setPropertyDropdown(featuresOptionDataResponse);
        setDataLoading(false);
      }
    });
  }, [dispatch, getRowData])

  useEffect(() => {
    const result = propertyDropdown?.filter(item => item.childValue?.some(child => defaultSelected.includes(child.id)))
      .map(item => item.value);
    setInitialData({
      property: result[0],
      values: defaultSelected
    });
    setSelectedProperty(result[0]);
    setSelectedValues(defaultSelected);
  }, [propertyDropdown, defaultSelected])

  useEffect(() => {
    const dropdownValue = propertyDropdown?.find((data) => data?.value === selectedProperty);
    setValuesDropdown(dropdownValue?.childValue?.map((i) => ({
      value: i.id,
      label: `${dropdownValue?.label}: ${i?.value || i.id}`
    })));
  }, [propertyDropdown, selectedProperty])

  useEffect(() => {
    const dropdownValue = valuesDropdown?.filter(item => selectedValues.includes(item.value));
    setSelectedValuesObject(dropdownValue)
  }, [selectedValues, valuesDropdown])

  return (
    <>
      <div className="select-table-container">
        <div className="row">
          <div className="col-sm-12">
            <CardBody>
              <Formik
                initialValues={initialData}
                enableReinitialize
              >
                {({
                  values,
                  handleBlur,
                  setFieldValue,
                  dirty,
                }) => {
                  values?.values?.length > 0 && setSelectedValues(values?.values);
                  return (
                    <form
                    >
                      <div className="mt-3 form-row">
                        <div className="col-xl-6 col-md-6">
                          <h4>Properties</h4>
                          <Field
                            component={AutocompleteForFormik}
                            id="property"
                            name="property"
                            label="Properties"
                            as="select"
                            onBlur={handleBlur}
                            onChange={(data) => {
                              setSelectedProperty(data);
                              setFieldValue('values', []);
                            }}
                            value={values.property || []}
                            placeholder="Select Property"
                            options={propertyDropdown}
                          />
                        </div>
                        <div className="col-xl-6 col-md-6">
                          <h4>Values</h4>
                          <Field
                            component={AutocompleteForFormikMulti}
                            id="values"
                            name="values"
                            label="Values"
                            as="select"
                            onBlur={handleBlur}
                            onChange={onchange}
                            value={values.values || []}
                            placeholder="Select Values"
                            options={valuesDropdown}
                          />
                        </div>
                      </div>
                    </form>
                  )
                }}
              </Formik>
            </CardBody>
          </div>
        </div>
        <div className="select-table-footer row">
          <div className="col select-table-footer-btn-clm">
            <Button
              type="submit"
              variant="outlined"
              size="large"
              onClick={() => { handleClose(); }}
            >
              Close
            </Button>
            {!staticValues && <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={dataLoading}
              onClick={() => onConfirm(selectedValues, selectedValuesObject)}
            >
              Confirm
            </Button>}
          </div>
        </div>
      </div>
    </>
  )
}

export default DependentDropdown
