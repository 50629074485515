/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

// Forms
import {
  Button,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import { Field, FieldArray, Formik } from "formik";
import * as Yup from "yup";

// Lodash
import { setStateOptions } from "_store/common.slice";
import { isEmpty, merge, omitBy, orderBy } from "lodash";

// Store
import { createAlert } from "_store/alert.slice";
import {
  addStoneWrapper,
  deleteStone,
  getAllStones,
  getStoneById,
  getStonePropertiesByStoneTypeId,
} from "_store/pim/stone.slice";
import { getTagsData } from "_store/pim/tag.slice";
import { upStreamActions } from "_store/upStream.slice";

//Components
import ApiErrorMsg from "_components/ApiErrorMsg";
import AutocompleteForFormikMulti from "_components/AutocompleteForFormikMulti";
import ErrorMessage from "_components/ErrorMessage";
import InnerLoader from "_components/InnerLoader";
import { CardBody, HeaderTitle } from "_components/pim";
import ScheduleLock from "_components/ScheduleLock";
import ScheduleModal from "_components/ScheduleModal";

// utils
import AutocompleteForFormik from "_components/AutocompleteForFormik";
import FloatingButton from "_components/forms/FloatingButton";
import { InputFormFields } from "_components/forms/InputFormFields";
import { PropertyCalculatedFieldValueLabel } from "_components/forms/PropertyCalculatedFieldValueLabel";
import { PropertyInputFormFields } from "_components/forms/PropertyInputFormFields";
import { PropertySelectFormFieldsWithOutTranslate } from "_components/forms/PropertySelectFormFieldsWithOutTranslate";
import { generateCodeByType } from "_store/pim/metal.slice";
import { getPlaceholderList } from "_store/pim/placeholder.slice";
import { getScheduleById } from "_store/scheduleLocking.slice";
import { formattedErrorResponse, sortProperties } from "_utils/commonFunctions";
import { stoneFormValidation } from "_utils/validationSchemas";
import { useRedirectBack, useStateRedirectBack } from "hooks/useRedirectBack";
import IsSolrIndexable from "_components/forms/IsSolrIndexable";
import IsSolrSearchable from "_components/forms/IsSolrSearchable";
import FormLoader from '_components/FormLoader';

const initialSchema = Yup.object({});

const StoneForm = () => {
  const dispatch = useDispatch();
  const translations = useSelector((x) => x.translations);
  const [stoneTypeName, setStoneTypeName] = useState();
  const [validationSchema, setValidationSchema] = useState(initialSchema);
  const [rawStoneData, setRawStoneData] = useState();
  const [stoneData, setStoneData] = useState();
  const [stonePropertyValues, setStonePropertyValues] = useState();
  const [showForm, setShowForm] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [valuesLoading, setValuesLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(true);
  const [codeLoader, setCodeLoader] = useState(false);
  const [stoneTagOptions, setStoneTagOptions] = useState([]);
  let { id, action } = useParams();
  const [searchParams] = useSearchParams();
  const typeId = searchParams.get("typeId") ? searchParams.get("typeId") : "";
  const versionHistoryId = searchParams.get("versionHistoryId")
    ? searchParams.get("versionHistoryId")
    : "";
  const listPage = searchParams.get("listPage")
    ? searchParams.get("listPage")
    : "";
  const readOnly = action === "view" || listPage === "versionHistoryView";
  const label = searchParams.get("label") ? searchParams.get("label") : "";
  const refs = useRef(true);
  const [loading, setLoading] = useState({
    tagsList: true,
  });
  const [calcPropertyValues, setCalcPropertyValues] = useState({});
  const [accessSetting, setAccessSetting] = useState({
    canEdit: true,
    canSchedule: true,
    canUnlock: false,
    isAllowUserEdit: true,
    stones: true,
    placeholders: true,
  });
  const [stoneList, setStoneList] = useState([]);
  const [isMixType, setIsMixType] = useState(false);
  const [placeholderList, setPlaceholderList] = useState([]);
  const [isLatestRecord, setIsLatestRecord] = useState(true);
  const redirectBack = useRedirectBack();
  const redirectBackState = useStateRedirectBack();

  useEffect(() => {
    if (Array.isArray(translations)) {
      dispatch(getStonePropertiesByStoneTypeId(typeId)).then((response) => {
        if (response?.payload) {
          if (response?.payload?.data?.length > 0) {
            setIsMixType(response?.payload?.data[0]?.isMixType);
            // processing raw data for ordering based on translation sequence
            let allowedPropertyValueData = sortProperties(
              response?.payload?.data[0]?.allowedPropertiesValueMap
            )?.map((item) => {
              let updatedItem = item;
              let updatedTranslates = [];
              if (
                updatedItem?.type?.propertyType === "calculatedFieldAddition"
              ) {
                updatedItem = item?.stonePropertiesMaps?.[0]?.linkStoneProperty;
                updatedItem.calcName = item?.name;
                updatedItem.calcId = item?.id;
                updatedItem.calcValue = item?.stonePropertiesMaps?.[0]?.value;
              }

              updatedItem.stonePropertyTranslates?.forEach((subitem) => {
                let updatedSubItem = subitem;
                subitem.sequence = translations?.find(
                  (t) => t.translate_id === subitem.translateId
                )?.sequence;
                updatedTranslates.push(updatedSubItem);
              });
              let updatedPropertyValueTranslates = [];
              if (updatedItem?.stonePropertyValues?.length) {
                updatedItem.stonePropertyValues?.[0]?.stonePropertyValuesTranslates?.forEach(
                  (subitem) => {
                    let updatedSubItem = subitem;
                    subitem.sequence = translations?.find(
                      (t) => t.translate_id === subitem.translateId
                    )?.sequence;
                    updatedPropertyValueTranslates.push(updatedSubItem);
                  }
                );
              }
              const sortedTranslates = orderBy(
                updatedTranslates,
                "sequence",
                "asc"
              );
              const sortedPropertyValueTranslates = orderBy(
                updatedPropertyValueTranslates,
                "sequence",
                "asc"
              );
              if (updatedItem) {
                updatedItem.stonePropertyTranslates = sortedTranslates;
                if (updatedItem?.stonePropertyValues?.length) {
                  updatedItem.stonePropertyValues[0].stonePropertyValuesTranslates =
                    sortedPropertyValueTranslates;
                }
              }
              return updatedItem;
            });
            // setting up updated data ordering based on translation sequence
            let updatedTypeNameTranslates = [];
            response?.payload?.data?.forEach((subitem) => {
              updatedTypeNameTranslates.push(subitem.name || "N/A");
            });
            setStoneTypeName(updatedTypeNameTranslates);
            setStonePropertyValues(allowedPropertyValueData);
            if (
              !allowedPropertyValueData ||
              allowedPropertyValueData?.length < 1
            ) {
              setDataLoading(false);
              setShowForm(true);
              setValuesLoading(false);
            }
          } else {
            setDataLoading(false);
            setShowForm(true);
          }
        }
      });
    }
  }, [dispatch, typeId, translations]);

  useEffect(() => {
    dispatch(getTagsData()).then((response) => {
      const stoneTagOptionsRaw = [];
      response?.payload?.forEach((item) => {
        item?.tagValues?.forEach((tagItem) => {
          stoneTagOptionsRaw.push({
            value: tagItem.id,
            label: tagItem.tagValue,
            tagType: item.tagName,
          });
        });
      });
      setStoneTagOptions(stoneTagOptionsRaw);
      setLoading((prevState) => ({
        ...prevState,
        tagsList: false,
      }));
    });
    dispatch(getPlaceholderList()).then((res) => {
      if (!res.error) {
        setPlaceholderList(res.payload?.data ?? []);
      }
      setLoading((prevState) => ({
        ...prevState,
        placeholders: false,
      }));
    });
    dispatch(getAllStones(`searchKey[relations][][isMixStone]=false`)).then(
      (res) => {
        if (!res.error) {
          setStoneList(res.payload?.data ?? []);
        }
        setLoading((prevState) => ({
          ...prevState,
          stones: false,
        }));
      }
    );
  }, []);

  const getPropertyValueMapping = (propertyItem, propertyData) => {
    let item = propertyItem;
    let data = propertyData;
    if (propertyItem.hasOwnProperty("calcId")) {
      const tempCalcPropertyValues = { ...calcPropertyValues };
      const tempData = propertyData?.stonePropertyValueMaps?.map((i) => {
        if (i?.property?.id === propertyItem?.calcId) {
          if (i?.stonePropertyValueMapTranslates) {
            tempCalcPropertyValues[i.propertyId] =
              i?.stonePropertyValueMapTranslates;
          }
          return i?.stoneCalculatedPropertyValueMaps;
        } else {
          return i;
        }
      });
      setCalcPropertyValues({ ...tempCalcPropertyValues });
      data.stonePropertyValueMaps = tempData?.flat();
    }
    if (item?.type?.propertyType?.toLowerCase() === "multiselect") {
      const stonePropertyTranslates = data?.stonePropertyValueMaps?.filter(
        (f) => f?.propertyId === item.id
      );
      return {
        propertyId: item.id,
        stonePropertyValueIds: stonePropertyTranslates?.map((item) => {
          return item.stonePropertyValuesId;
        }),
      };
    } else if (item?.type?.propertyType?.toLowerCase() === "list") {
      const stonePropertyTranslates = data?.stonePropertyValueMaps?.filter(
        (f) => f?.propertyId === item.id
      );
      return {
        propertyId: item.id,
        stonePropertyValueId:
          stonePropertyTranslates?.[0]?.stonePropertyValuesId,
      };
    } else if (item?.type?.propertyType?.toLowerCase() === "number") {
      const stonePropertyTranslates = data?.stonePropertyValueMaps?.filter(
        (f) => f?.propertyId === item.id
      );
      return {
        propertyId: item.id,
        stonePropertyValueMapsTranslates:
          stonePropertyTranslates[0]?.stoneCalculatedPropertyValueMapTranslates?.map(
            (tsp) => {
              return {
                translateId: tsp.translateId,
                number: String(tsp.number),
              };
            }
          ),
      };
    } else if (item?.type?.propertyType?.toLowerCase() === "text") {
      const stonePropertyTranslates = data?.stonePropertyValueMaps?.filter(
        (f) => f?.propertyId === item.id
      );
      return {
        propertyId: item.id,
        stonePropertyValueMapsTranslates:
          stonePropertyTranslates?.[0]?.stonePropertyValueMapTranslates?.map(
            (tsp) => {
              return {
                translateId: tsp.translateId,
                propertyValue: tsp.propertyValue,
              };
            }
          ),
      };
    }
  };

  const getPropertyMapping = (item) => {
    let valitem;
    if (item.type.propertyType?.toLowerCase() === "multiselect") {
      valitem = {
        propertyId: item.id,
        stonePropertyValueIds: [],
      };
    } else if (item.type.propertyType?.toLowerCase() === "list") {
      valitem = {
        propertyId: item.id,
        stonePropertyValueId: "",
      };
    } else if (item.type.propertyType?.toLowerCase() === "number") {
      valitem = {
        propertyId: item.id,
        stonePropertyValueMapsTranslates: item?.stonePropertyTranslates?.map(
          (tsp) => {
            return {
              translateId: tsp.translateId,
              number: "",
            };
          }
        ),
      };
    } else if (item.type.propertyType?.toLowerCase() === "text") {
      valitem = {
        propertyId: item.id,
        stonePropertyValueMapsTranslates: item?.stonePropertyTranslates?.map(
          (tsp) => {
            return {
              translateId: tsp.translateId,
              propertyValue: "",
            };
          }
        ),
      };
    }
    return valitem;
  };

  useEffect(() => {
    setValidationSchema(stoneFormValidation());
    if (Array.isArray(translations) && stonePropertyValues) {
      let formData = {
        code: "",
        name: "",
        stoneTypeId: typeId,
        stonePropertyValueMaps: [],
        stoneTranslates: [],
        tagValueIds: [],
        isMixStone: isMixType,
        mixedStoneMaps: [],
        isSolrIndexable: false,
        isSolrSearchable: false,
      };
      translations.forEach((item) => {
        formData.stoneTranslates.push({
          name: "",
          translateId: item.translate_id,
        });
      });
      stonePropertyValues.forEach((item) => {
        let valitem;
        if (
          item?.type?.propertyType?.toLowerCase() === "calculatedfieldaddition"
        ) {
          if (item?.stonePropertiesMaps?.[0]?.linkStoneProperty) {
            valitem = getPropertyMapping(
              item?.stonePropertiesMaps?.[0]?.linkStoneProperty
            );
          }
        } else {
          valitem = getPropertyMapping(item);
        }
        formData.stonePropertyValueMaps.push(valitem);
      });
      setRawStoneData(formData);
    }
  }, [action, id, translations, stonePropertyValues, typeId, isMixType]);

  const loadFormData = () => {
    if (action !== "add" && action) {
      let apiResp;
      let schedule = null;
      if (listPage === "scheduleEdit") {
        apiResp = dispatch(getScheduleById(id));
      } else {
        const apiData = { id, params: {} };
        if (action === "edit") {
          apiData.params = { action: "edit" };
        }
        apiResp = dispatch(getStoneById(apiData));
      }
      apiResp.then((response) => {
        if (response?.payload) {
          let data = response?.payload?.data?.[0];
          if (listPage === "scheduleEdit") {
            setAccessSetting({
              canEdit: data?.canEdit ?? true,
              canSchedule: data?.canSchedule,
              canUnlock: data?.canUnlock,
              isAllowUserEdit: data?.isAllowUserEdit ?? true,
            });
            let { modelData, name, description, scheduleTime, id } = data;
            try {
              schedule = {
                id,
                name,
                description,
                scheduleTime,
              };
              data = JSON.parse(modelData ?? "");
            } catch (error) {
              console.log(error);
            }
          } else {
            setAccessSetting({
              canEdit: data?.canEdit ?? true,
              canSchedule: data?.canSchedule,
              canUnlock: data?.canUnlock,
              isAllowUserEdit: data?.isAllowUserEdit ?? true,
            });
            dispatch(
              setStateOptions({
                options: response?.payload?.stateOptions ?? [],
                data: data?.stoneState ?? {},
              })
            );
          }
          setIsLatestRecord(data?.isLatestRecord);
          let formData = {
            id,
            code: data?.code ?? "",
            name: data?.name ?? "",
            stoneTypeId: typeId,
            stonePropertyValueMaps: [],
            stoneTranslates: [],
            tagValueIds: [],
            mixedStoneMaps:
              data?.mixedStoneMaps?.map(({ placeholderId, stoneId }) => ({
                placeholderId,
                stoneId,
              })) ?? [],
            isMixStone: data?.isMixStone,
            isSolrIndexable: data?.isSolrIndexable,
            isSolrSearchable: data?.isSolrSearchable,
            objectId: data?.objectId,
            scheduled: data?.scheduled,
            lockTimeOut: data?.lockTimeOut,
            lockedBy: data?.lockedBy,
            schedule,
          };
          translations.forEach((item) => {
            const nameById = data?.stoneTranslates?.find(
              (el) => el.translateId === item?.translate_id
            );
            formData.stoneTranslates.push({
              name: nameById?.name || "",
              translateId: item?.translate_id,
            });
          });

          stonePropertyValues.forEach((item) => {
            let valitem = getPropertyValueMapping(item, data);

            let updatedItem = valitem;
            let updatedTranslates = [];
            updatedItem.stonePropertyValueMapsTranslates?.forEach((subitem) => {
              let updatedSubItem = subitem;
              subitem.sequence = translations?.find(
                (t) => t?.translate_id === subitem?.translateId
              )?.sequence;
              //return updatedSubItem
              updatedTranslates.push(updatedSubItem);
            });
            const sortedTranslates = orderBy(
              updatedTranslates,
              "sequence",
              "asc"
            );
            updatedItem.stonePropertyValueMapsTranslates = sortedTranslates;
            // setting up updated data ordering based on translation sequence

            formData.stonePropertyValueMaps.push(updatedItem);
          });
          const tagValueIds = [];
          data?.tagValues?.forEach((tagItem) => {
            tagValueIds.push(tagItem.id);
          });
          formData.tagValueIds = tagValueIds;
          const finalFormData = merge(rawStoneData, formData);
          setStoneData(finalFormData);
          setShowForm(true);
        } else {
          setErrorMsg(formattedErrorResponse(response?.error));
        }
        setDataLoading(false);
      });
    } else {
      setValuesLoading(false);
      setStoneData(rawStoneData);
      setDataLoading(false);
      setShowForm(true);
    }
  };
  useEffect(() => {
    const firstRender = refs.current;
    if (firstRender && rawStoneData) {
      refs.current = false;
      loadFormData();
    }
  }, [loadFormData, rawStoneData]);

  const editDetailWithUpStream = (formData = {}) => {
    dispatch(
      upStreamActions.setUpStreamModal({
        id,
        title: "Stone - Upstream update",
        formData,
        wrapperUrl: "/stone/wrapper-stone/update/",
        getUpstreamUrl: "/stone/update/getupstreamdata",
        submitUrl: "/stone/update/save",
        state: {
          state: {
            type: typeId
          }
        },
        successRedirectUrl:
          listPage === "versionHistory"
            ? `/pim/stone/stones/${versionHistoryId}/versions?label=${label}&typeId=${typeId}`
            : "/pim/stone/stones",
      })
    );
  };

  const onFormSubmit = (values) => {
    const stonePropertyValueMaps = values?.stonePropertyValueMaps?.map(
      (item) => {
        const calcProperty = stonePropertyValues?.filter(
          (property) =>
            property.hasOwnProperty("calcId") && item.propertyId === property.id
        );
        if (calcProperty?.length > 0) {
          if (item.hasOwnProperty("stonePropertyValueMapsTranslates")) {
            item.stoneCalculatedPropertyValueMapTranslates =
              item.stonePropertyValueMapsTranslates;
            delete item.stonePropertyValueMapsTranslates;
          }
          return {
            propertyId: calcProperty?.[0]?.calcId,
            stoneCalculatedPropertyValueMaps: [item],
          };
        } else {
          return item;
        }
      }
    );
    values.stonePropertyValueMaps = [...stonePropertyValueMaps];
    let formData = omitBy(values, (v) => v === null);
    if (action === "edit") {
      editDetailWithUpStream(formData);
      return true;
    } else {
      return dispatch(addStoneWrapper(formData)).then((res) => {
        if (!res.error) {
          dispatch(
            createAlert({
              alertType: "success",
              msg: "Stone Added successfully",
              timeout: 5000,
            })
          );
          redirectBackState("add");
        }
      });
    }
  };

  const generateCode = (setFieldValue) => {
    if (!codeLoader) {
      setCodeLoader(true);
      dispatch(generateCodeByType({ type: "stoneType", typeId: typeId }))
        .then((response) => {
          setCodeLoader(false);
          if (response?.payload) {
            setFieldValue("code", response?.payload?.code ?? "");
          }
        })
        .catch(() => {
          setCodeLoader(false);
        });
    }
  };

  const handleModalAction = (params) => {
    if (params.action === "Delete") {
      return dispatch(deleteStone({ id, deletePermanent: readOnly }))
        .then((res) => {
          if (!res.error) {
            dispatch(
              createAlert({
                alertType: "success",
                msg: `Stone Deleted ${
                  readOnly ? "Permanent" : ""
                } successfully`,
                timeout: 5000,
              })
            );
            redirectBack("delete");
          }
        })
        .catch((err) => console.log("error", err));
    }
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        <HeaderTitle
          action={action}
          title={"Stone"}
          type={typeId}
          isLatestRecord={isLatestRecord}
          showTag
          onBackClick={redirectBack}
        />
        {showForm && !dataLoading && stoneData ? (
          <CardBody>
            <FormLoader />
            <FloatingButton
              id={id}
              deleteAction={"Stone"}
              handleModalAction={handleModalAction}
              isDisableDelete={listPage === "versionHistory" || readOnly}
              changeStateUrl="stone/change-state/"
            />
            <Formik
              initialValues={stoneData}
              validationSchema={validationSchema}
              onSubmit={onFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                dirty,
                isSubmitting,
                setFieldValue,
                setErrors,
              }) => {
                return (
                  <>
                    <form
                      onSubmit={handleSubmit}
                      className={readOnly ? "readOnly-form" : ""}
                    >
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <h4>Stone Type</h4>
                          <div className="mb-2">{stoneTypeName}</div>
                        </div>
                        <div className="form-group col-md-12">
                          <div className="row row-sm">
                            <div className="col-xl-3 col-md-4">
                              <h4>
                                Name<sup className="required">*</sup>
                              </h4>
                              <TextField
                                id="name"
                                label="Name"
                                name="name"
                                value={values?.name}
                                margin="normal"
                                fullWidth
                                error={Boolean(errors?.name && touched?.name)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={readOnly}
                              />
                              {errors?.name && touched?.name ? (
                                <ErrorMessage error={errors?.name} />
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="form-group col-md-12">
                          <div className="row row-sm">
                            <div className="col-xl-3 col-md-4">
                              <div className="codeWrapper">
                                <h4>Code</h4>
                                {!readOnly && (
                                  <p
                                    className="generateCodeBtn"
                                    onClick={() => generateCode(setFieldValue)}
                                  >
                                    {!codeLoader ? (
                                      <>Generate Code</>
                                    ) : (
                                      <div className="loader">
                                        <div className="spinner-border spinner-border-sm"></div>
                                      </div>
                                    )}
                                  </p>
                                )}
                              </div>
                              <TextField
                                id="code"
                                label={`Code`}
                                name="code"
                                value={values?.code}
                                margin="normal"
                                fullWidth
                                error={Boolean(errors?.code && touched?.code)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={readOnly}
                              />
                              {errors?.code && touched?.code ? (
                                <ErrorMessage error={errors?.code} />
                              ) : null}
                            </div>
                            {/* <div className={`col-xl-3 col-md-4`}>
                              <h4>&nbsp;</h4>
                              <FormControlLabel
                                name="isMixStone"
                                control={
                                  <Switch
                                    name="isMixStone"
                                    checked={isMixType || values?.isMixStone}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `isMixStone`,
                                        e.target.checked ? true : false
                                      );
                                    }}
                                  />
                                }
                                label="Is Mix Stone"
                              />
                            </div> */}
                            <IsSolrIndexable
                              values={values}
                              errors={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                            />
                            <IsSolrSearchable
                              values={values}
                              errors={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                        </div>
                        <InputFormFields
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          name="stoneTranslates"
                          valueKey="name"
                          label="Display Name"
                          labelType="h4"
                          disabled={readOnly}
                        />
                      </div>

                      {stoneTagOptions?.length > 0 && (
                        <div className="form-row">
                          <div className="col-md-12">
                            <h4 className="m-0 mb-2">Tags</h4>
                          </div>
                          <div className="col-xl-3 col-md-4">
                            <Field
                              component={AutocompleteForFormikMulti}
                              labelid="tag-label"
                              id="tag"
                              name="tagValueIds"
                              label={loading.tagsList ? "Loading..." : "Tags"}
                              as="select"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values?.tagValueIds}
                              placeholder="Select Tags"
                              options={stoneTagOptions}
                              groupBy={(option) => option.tagType}
                              disabled={
                                loading.tagsList ||
                                readOnly
                              }
                              readOnly={readOnly}
                            />
                          </div>
                        </div>
                      )}
                      {isMixType ? (
                        <div className="form-row">
                          <div className="col-md-12">
                            <div className="mt-3 d-flex justify-content-between align-items-center">
                              <h3 className="m-0 mb-2">Mixed Stone Maps</h3>
                            </div>
                            <Divider
                              style={{
                                marginTop: 7,
                                marginBottom: 7,
                                opacity: 0.5,
                              }}
                            />
                            <FieldArray
                              name="mixedStoneMaps"
                              render={(arrayRootHelpers) => (
                                <div>
                                  {values?.mixedStoneMaps?.map(
                                    (value, index) => (
                                      <div
                                        className="add-remove-box"
                                        key={`mixedStoneMaps-${index + 1}`}
                                      >
                                        <div className="form-row w-100 mb-3">
                                          <div className="col-lg-4">
                                            <Field
                                              component={AutocompleteForFormik}
                                              labelid="allowedPropertiesValueMap-label"
                                              id={`mixedStoneMaps[${index}].placeholderId`}
                                              name={`mixedStoneMaps[${index}].placeholderId`}
                                              label={
                                                loading.placeholders
                                                  ? "Loading..."
                                                  : "Stone Team"
                                              }
                                              as="select"
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              value={value.placeholderId}
                                              placeholder="Stone Team"
                                              disabled={
                                                loading.placeholders || readOnly
                                              }
                                              readOnly={readOnly}
                                              options={placeholderList?.map(
                                                (item) => ({
                                                  label:
                                                    item?.name ||
                                                    item?.referenceId,
                                                  value: item.id,
                                                })
                                              )}
                                            />
                                          </div>
                                          <div className="col-lg-4">
                                            <Field
                                              component={AutocompleteForFormik}
                                              labelid="allowedPropertiesValueMap-label"
                                              id={`mixedStoneMaps[${index}].stoneId`}
                                              name={`mixedStoneMaps[${index}].stoneId`}
                                              label={
                                                loading.stones
                                                  ? "Loading..."
                                                  : "Stone"
                                              }
                                              as="select"
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              value={value.stoneId}
                                              placeholder="Stone"
                                              disabled={
                                                loading.stones || readOnly
                                              }
                                              readOnly={readOnly}
                                              options={stoneList?.map(
                                                (item) => ({
                                                  label:
                                                    item?.name ||
                                                    item?.referenceId,
                                                  value: item.id,
                                                })
                                              )}
                                            />
                                          </div>
                                        </div>
                                        {!readOnly && (
                                          <IconButton
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            onClick={() => {
                                              arrayRootHelpers.remove(index);
                                            }}
                                          >
                                            <span className="material-icons">
                                              cancel
                                            </span>
                                          </IconButton>
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                            />
                            {!readOnly && (
                              <Button
                                variant="outlined"
                                sx={{ mt: 1, mb: 1 }}
                                style={{
                                  float:
                                    values?.mixedStoneMaps.length > 0
                                      ? "right"
                                      : "left",
                                }}
                                size="small"
                                onClick={() => {
                                  let newListValues =
                                    values?.mixedStoneMaps ?? [];
                                  newListValues.push({
                                    placeholderId: "",
                                    stoneId: "",
                                  });
                                  setFieldValue(
                                    "mixedStoneMaps",
                                    newListValues
                                  );
                                }}
                              >
                                <span className="mr-1 material-icons">add</span>{" "}
                                Add
                              </Button>
                            )}
                          </div>
                        </div>
                      ) : null}
                      <div className="form-row">
                        <div className="col-md-12">
                          <div className="mt-3 d-flex justify-content-between align-items-center">
                            <h3 className="m-0 mb-2 ">Stone Properties</h3>
                          </div>

                          <FieldArray
                            name="stonePropertyValueMaps"
                            render={(arrayRootHelpers) => (
                              <>
                                {values?.stonePropertyValueMaps?.length > 0 &&
                                  !valuesLoading && (
                                    <div>
                                      {stonePropertyValues?.map(
                                        (item, index) => (
                                          <div key={item.id}>
                                            <Divider
                                              style={{
                                                marginTop: 7,
                                                marginBottom: 7,
                                                opacity: 0.5,
                                              }}
                                            />
                                            <div className="row">
                                              {item?.calcName &&
                                                item?.calcName !== "" && (
                                                  <>
                                                    <div className="col-xl-3 col-lg-4"></div>
                                                    <div className="col-xl-9 col-lg-8">
                                                      <PropertyCalculatedFieldValueLabel
                                                        name={item?.calcName}
                                                        values={
                                                          calcPropertyValues[
                                                            item?.calcId
                                                          ]
                                                        }
                                                        disabled={readOnly}
                                                      />
                                                    </div>
                                                  </>
                                                )}
                                              <div className="col-xl-3 col-lg-4">
                                                <h4 className="m-0 mt-1">
                                                  {item?.name || "N/A"}
                                                </h4>
                                              </div>
                                              <div className="col-xl-9 col-lg-8">
                                                <div className="row">
                                                  <div className="col-xl-12">
                                                    {item?.type?.propertyType?.toLowerCase() ===
                                                      "text" && (
                                                      <PropertyInputFormFields
                                                        item={item}
                                                        index={index}
                                                        arrayName="stonePropertyValueMapsTranslates"
                                                        valueKey="stonePropertyValueMaps"
                                                        translateKey="stonePropertyValueMapsTranslates"
                                                        translationsMap="stonePropertyTranslates"
                                                        targetItemMapping="stonePropertyValues[0].stonePropertyValuesTranslates"
                                                        targetValueMapping={`stonePropertyValueMaps[${index}].stonePropertyValueMapsTranslates`}
                                                        targetUnitMapping={`stoneUnit.stoneUnitTranslates`}
                                                        name={`propertyValue`}
                                                        value={
                                                          values
                                                            ?.stonePropertyValueMaps[
                                                            index
                                                          ]
                                                            .stonePropertyValueMapsTranslates
                                                        }
                                                        values={values}
                                                        unitBox={true}
                                                        inputType={"text"}
                                                        valuesLoading={
                                                          valuesLoading
                                                        }
                                                        margin="normal"
                                                        fullWidth
                                                        errors={errors}
                                                        touched={touched}
                                                        setFieldValue={
                                                          setFieldValue
                                                        }
                                                        handleChange={
                                                          handleChange
                                                        }
                                                        handleBlur={handleBlur}
                                                        setErrors={setErrors}
                                                        disabled={
                                                          readOnly
                                                        }
                                                      />
                                                    )}
                                                    {item?.type?.propertyType?.toLowerCase() ===
                                                      "number" && (
                                                      <PropertyInputFormFields
                                                        item={item}
                                                        index={index}
                                                        arrayName="stonePropertyValueMapsTranslates"
                                                        valueKey="stonePropertyValueMaps"
                                                        translateKey="stonePropertyValueMapsTranslates"
                                                        translationsMap="stonePropertyTranslates"
                                                        targetItemMapping="stonePropertyValues[0].stonePropertyValuesTranslates"
                                                        targetValueMapping={`stonePropertyValueMaps[${index}].stonePropertyValueMapsTranslates`}
                                                        targetUnitMapping={`stoneUnit.stoneUnitTranslates`}
                                                        name={`number`}
                                                        value={
                                                          values
                                                            ?.stonePropertyValueMaps[
                                                            index
                                                          ]
                                                            .stonePropertyValueMapsTranslates
                                                        }
                                                        values={values}
                                                        unitBox={true}
                                                        inputType={"number"}
                                                        valuesLoading={
                                                          valuesLoading
                                                        }
                                                        margin="normal"
                                                        fullWidth
                                                        errors={errors}
                                                        touched={touched}
                                                        setFieldValue={
                                                          setFieldValue
                                                        }
                                                        handleChange={
                                                          handleChange
                                                        }
                                                        handleBlur={handleBlur}
                                                        setErrors={setErrors}
                                                        disabled={
                                                          readOnly
                                                        }
                                                      />
                                                    )}

                                                    {item?.type?.propertyType?.toLowerCase() ===
                                                      "list" && (
                                                      <PropertySelectFormFieldsWithOutTranslate
                                                        item={item}
                                                        translateKey="stonePropertyValuesTranslates"
                                                        targetUnitMapping={`stoneUnit.stoneUnitTranslates`}
                                                        name={`stonePropertyValueMaps[${index}].stonePropertyValueId`}
                                                        value={
                                                          values
                                                            ?.stonePropertyValueMaps?.[
                                                            index
                                                          ]
                                                            ?.stonePropertyValueId
                                                        }
                                                        inputType={"list"}
                                                        unitBox={true}
                                                        optionsMapping={
                                                          "stonePropertyValues"
                                                        }
                                                        handleChange={
                                                          handleChange
                                                        }
                                                        handleBlur={handleBlur}
                                                        readOnly={
                                                          readOnly
                                                        }
                                                        disabled={
                                                          readOnly
                                                        }
                                                      />
                                                    )}

                                                    {item?.type?.propertyType?.toLowerCase() ===
                                                      "multiselect" && (
                                                      <PropertySelectFormFieldsWithOutTranslate
                                                        item={item}
                                                        translateKey="stonePropertyValuesTranslates"
                                                        targetUnitMapping={`stoneUnit.stoneUnitTranslates`}
                                                        name={`stonePropertyValueMaps[${index}].stonePropertyValueIds`}
                                                        value={
                                                          values
                                                            ?.stonePropertyValueMaps?.[
                                                            index
                                                          ]
                                                            ?.stonePropertyValueIds
                                                        }
                                                        inputType={
                                                          "multiselect"
                                                        }
                                                        unitBox={true}
                                                        optionsMapping={
                                                          "stonePropertyValues"
                                                        }
                                                        handleChange={
                                                          handleChange
                                                        }
                                                        handleBlur={handleBlur}
                                                        readOnly={
                                                          readOnly
                                                        }
                                                        disabled={
                                                          readOnly
                                                        }
                                                      />
                                                    )}
                                                    {errors
                                                      ?.stonePropertyValueMaps?.[
                                                      index
                                                    ]
                                                      ?.stonePropertyValueMapsTranslates &&
                                                    touched
                                                      ?.stonePropertyValueMaps?.[
                                                      index
                                                    ]
                                                      ?.stonePropertyValueMapsTranslates
                                                      .length > 0 ? (
                                                      <ErrorMessage
                                                        error={
                                                          errors
                                                            ?.stonePropertyValueMaps?.[
                                                            index
                                                          ]
                                                            ?.stonePropertyValueMapsTranslates
                                                        }
                                                      />
                                                    ) : null}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                      {!["deletedView", "versionHistoryView"].includes(
                        listPage
                      ) &&
                        accessSetting?.isAllowUserEdit && (
                          <>
                            {!readOnly && (
                              <div className="formButtonsRow">
                                {listPage !== "scheduleEdit" && (
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    size="large"
                                    disabled={
                                      !dirty || !isEmpty(errors) || isSubmitting
                                    }
                                  >
                                    {action === "edit" ? "Update" : "Save"}
                                  </Button>
                                )}
                                <ScheduleModal
                                  name="Schedule Stone"
                                  modelName="stones"
                                  listingURL={`..?typeId=${typeId}`}
                                  handleSubmit={handleSubmit}
                                  values={values}
                                  disabled={
                                    !dirty || !isEmpty(errors) || isSubmitting
                                  }
                                  setFieldValue={setFieldValue}
                                  redirectBack={redirectBack}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  accessSetting={accessSetting}
                                />
                              </div>
                            )}
                            {readOnly && (
                              <ScheduleLock
                                editURL={`../${id}/edit?typeId=${typeId}`}
                                listingURL={`..?typeId=${typeId}`}
                                refs={refs}
                                modelName="stones"
                                modelId={values.objectId}
                                scheduled={values?.scheduled}
                                lockedBy={values.lockedBy}
                                lockTimeOut={values.lockTimeOut}
                                accessSetting={accessSetting}
                              />
                            )}
                          </>
                        )}
                    </form>
                  </>
                );
              }}
            </Formik>
          </CardBody>
        ) : null}

        {(dataLoading || valuesLoading) && <InnerLoader />}
        {errorMsg?.error && <ApiErrorMsg error={errorMsg?.error} />}
      </div>
    </div>
  );
};

export default StoneForm;
