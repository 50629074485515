import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper, generateUpdateApiQueryParams } from "_utils";

const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "stoneData";


export const deleteStone = createAsyncThunk(
  `${sliceName}/deleteStone`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(`${baseUrl}/stone/delete${deletePermanent ? '-permanent' : ''}/${id}`, {
      id,
    });
    return res;
  }
);

export const getStonesRowData = createAsyncThunk(
  `${sliceName}/getStonesRowData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/stone/getAll?${params || ""}`)
);

export const getStoneTypeRowData = createAsyncThunk(
  `${sliceName}/getStoneTypeRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/stone/stone-type/getAll?${params || ""}`)
);

export const getStoneAllWithDetailsData = createAsyncThunk(
  `${sliceName}/getStoneAllWithDetailsData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/stone/stone-type/dropdown/getAllWithDetails`)
);

export const getStoneTypeList = createAsyncThunk(
  `${sliceName}/getStoneTypeList`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/stone/stone-type/dropdown/getAll?${params || ""}`)
);


export const getStoneTagByStoneId = createAsyncThunk(
  `${sliceName}/getStoneTagByStoneId`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/stone/stone-type/${id}/all-tags/get`)
);

export const getStonesMetaDataByStoneId = createAsyncThunk(
  `${sliceName}/getStonesMetaData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/stone/getMetadata?${params || ""}`)
);

export const getAllStones = createAsyncThunk(
  `${sliceName}/getAllStones`,
  async (params) => await fetchWrapper.get(`${baseUrl}/stone/dropdown/getAll?${params || ""}`)
);

export const getAllStoneTypes = createAsyncThunk(
  `${sliceName}/getAllStoneTypes`,
  async () => await fetchWrapper.get(`${baseUrl}/stone/stone-type/getAll`)
);

export const getStoneById = createAsyncThunk(
  `${sliceName}/getStoneById`,
  async (apiParams) =>{
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/stone/get/${id}${params ? `?${params || ""}` : ''}`
    )}
);

export const addStoneWrapper = createAsyncThunk(
  `${sliceName}/addStoneWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/stone/wrapper-stone/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const addStoneBulkUpdates = createAsyncThunk(
  `${sliceName}/addStoneBulkUpdates`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/stone/bulk-update`,
      values
    );
    return res || res === "" ? res : null;
  }
);

//getStonePropertiesByStoneTypeId
export const getStonePropertiesByStoneTypeId = createAsyncThunk(
  `${sliceName}/getStonePropertiesBySotneTypeId`,
  async (id) => await fetchWrapper.get(`${baseUrl}/stone/stone-type/get/${id}`)
);

export const getStoneUnitMetaData = createAsyncThunk(
  `${sliceName}/getStoneUnitMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/stone/stone-unit/getMetadata`)
);

export const getStoneUnitRowData = createAsyncThunk(
  `${sliceName}/getStoneUnitRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/stone/stone-unit/getAll?${params || ""}`)
);

export const deleteStoneUnit = createAsyncThunk(
  `${sliceName}/deleteStoneUnit`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/stone/stone-unit/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const getStoneUnitById = createAsyncThunk(
  `${sliceName}/getStoneUnitById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/stone/stone-unit/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const addStoneUnit = createAsyncThunk(
  `${sliceName}/addStoneUnit`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/stone/stone-unit/add`,
      values
    );

    return res || res === "" ? res : null;
  }
);

export const getStoneTypeById = createAsyncThunk(
  `${sliceName}/getStoneTypeById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/stone/stone-type/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const getAllStoneProperties = createAsyncThunk(
  `${sliceName}/getAllStoneProperties`,
  async () => await fetchWrapper.get(`${baseUrl}/stone/stone-property/dropdown/getAll`)
);

export const getFeatureOptionList = createAsyncThunk(
  `${sliceName}/getFeatureOptionList`,
  async (params) => await fetchWrapper.get(`${baseUrl}/feature/getFeatureOptionList?${params || ""}`)
);

export const addStoneTypeWrapper = createAsyncThunk(
  `${sliceName}/addStoneTypeWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/stone/stone-type/wrapper-stone-type/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const getStoneTypeMetaData = createAsyncThunk(
  `${sliceName}/getStoneTypeMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/stone/stone-type/getMetadata`)
);

export const deleteStoneType = createAsyncThunk(
  `${sliceName}/deleteStoneType`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/stone/stone-type/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const getStonePropertiesMetaData = createAsyncThunk(
  `${sliceName}/getStonePropertiesMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/stone/stone-property/getMetadata`)
);

export const getStonePropertiesRowData = createAsyncThunk(
  `${sliceName}/getStonePropertiesRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/stone/stone-property/getAll?${params || ""}`)
);

export const getStonePropertiesValueData = createAsyncThunk(
  `${sliceName}/getStonePropertiesValueData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/stone/stone-property/values/dropdown/getAll`)
);

export const getStonePropertiesDimensionalStoneset = createAsyncThunk(
  `${sliceName}/getStonePropertiesDimensionalStoneset`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/stone/stone-property/getAll/dimensional-stoneset?${params || ""}`)
);

export const deleteStoneProperty = createAsyncThunk(
  `${sliceName}/deleteStoneProperty`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/stone/stone-property/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const getAllStoneUnits = createAsyncThunk(
  `${sliceName}/getAllStoneUnits`,
  async () => await fetchWrapper.get(`${baseUrl}/stone/stone-unit/getAll`)
);

export const getStonePropertyById = createAsyncThunk(
  `${sliceName}/getStonePropertyById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/stone/stone-property/get/${id}?filter[include][][relation]=stonePropertyValues${params ? `&${params || ""}` : ''}`
    )
  }
);

export const addStonePropertyWrapper = createAsyncThunk(
  `${sliceName}/addStonePropertyWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/stone/stone-property/wrapper-stone-property-value/add`,
      values
    );
    return res || res === "" ? values : null;
  }
);

/**
 * version history
 */

export const getStonesMetaDataByStoneIdForVersion = createAsyncThunk(
  `${sliceName}/getStonesMetaDataForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/stone/getMetadata?versioning=true&${params || ""}`)
);

export const getStoneTypesMetaDataByStoneIdForVersion = createAsyncThunk(
  `${sliceName}/getStoneTypesMetaDataByStoneIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/stone/stone-type/getMetadata?versioning=true&${params || ""}`)
);

export const getStonePropertiesMetaDataByStoneIdForVersion = createAsyncThunk(
  `${sliceName}/getStonePropertiesMetaDataByStoneIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/stone/stone-property/getMetadata?versioning=true&${params || ""}`)
);

export const getStoneUnitsMetaDataByStoneIdForVersion = createAsyncThunk(
  `${sliceName}/getStoneUnitsMetaDataByStoneIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/stone/stone-unit/getMetadata?versioning=true&${params || ""}`)
);

export const getStonesRowDataByStoneId = createAsyncThunk(
  `${sliceName}/getStonesRowDataByStoneId`,
  async ({params,id}) => await fetchWrapper.get(`${baseUrl}/stone/version-history/get/${id}?${params || ""}`)
);

export const getStoneTypesRowDataByStoneId = createAsyncThunk(
  `${sliceName}/getStoneTypesRowDataByStoneId`,
  async ({params,id}) => await fetchWrapper.get(`${baseUrl}/stone/stone-type/version-history/get/${id}?${params || ""}`)
);

export const getStonePropertiesRowDataByStoneId = createAsyncThunk(
  `${sliceName}/getStonesRowDataForVersion`,
  async ({params,id}) => await fetchWrapper.get(`${baseUrl}/stone/stone-property/version-history/get/${id}?${params || ""}`)
);

export const getStoneUnitsRowDataByStoneId = createAsyncThunk(
  `${sliceName}/getStoneUnitsRowDataByStoneId`,
  async ({params,id}) => await fetchWrapper.get(`${baseUrl}/stone/stone-unit/version-history/get/${id}?${params || ""}`)
);


// Duplicate Actions
export const duplicateStone = createAsyncThunk(
  `${sliceName}/duplicateStone`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/stone/duplicate/${id}`)
);

export const duplicateStoneUnit = createAsyncThunk(
  `${sliceName}/duplicateStoneUnit`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/stone/stone-unit/duplicate/${id}`)
);

export const duplicateStoneType = createAsyncThunk(
  `${sliceName}/duplicateStoneType`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/stone/stone-type/duplicate/${id}`)
);

export const duplicateStoneProperty = createAsyncThunk(
  `${sliceName}/duplicateStoneProperty`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/stone/stone-property/duplicate/${id}`)
);

// ---------------------------------------------------------------------

export const stoneSlice = createSlice({
  name: sliceName,
  initialState: {},
});

export default stoneSlice.reducer;
